import React, { useState } from 'react';
import './checkout.css';
import { Link, useLocation } from "react-router-dom";
function CheckoutPage(props) {
  //const { state } = useLocation();
  // const totalAmount = state.totalAmount;
  const [shippingAddress, setShippingAddress] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [upiNumber, setUpiNumber] = useState('');
  const [emiDuration, setEmiDuration] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      shippingAddress,
      paymentMethod,
      cardNumber,
      cardName,
      expiryDate,
      cvv,
      upiNumber,
      emiDuration, // Include the EMI duration in the data
    };
    // TODO: Submit the data to the backend
  };
  

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      <div className="checkout-form__header">
        <h1>Checkout</h1>
        <span>totalAmount:</span>
      </div>
      <div className="checkout-form__section">
        <h2>Shipping Address</h2>
        <label>
          Full Name
          <input type="text" value={shippingAddress} onChange={(e) => setShippingAddress(e.target.value)} />
        </label>
        <label>
          Address Line 1
          <input type="text" />
        </label>
        <label>
          Address Line 2 (Optional)
          <input type="text" />
        </label>
        <div className="checkout-form__section__row">
          <label>
            City
            <input type="text" />
          </label>
          <label>
            State/Province/Region
            <input type="text" />
          </label>
          <label>
            ZIP/Postal Code
            <input type="text" />
          </label>
        </div>
      </div>
      <div className="checkout-form__section">
        <h2>Payment Method</h2>
        <div className="checkout-form__section__row">
          <label>
            <input type="radio" name="paymentMethod" value="creditCard" checked={paymentMethod === 'creditCard'} onChange={(e) => setPaymentMethod(e.target.value)} />
            Credit Card
          </label>
          <label>
            <input type="radio" name="paymentMethod" value="emi" checked={paymentMethod === 'emi'} onChange={(e) => setPaymentMethod(e.target.value)} />
            EMI
          </label>
          <label>
            <input type="radio" name="paymentMethod" value="upi" checked={paymentMethod === 'upi'} onChange={(e) => setPaymentMethod(e.target.value)} />
            UPI
          </label>
        </div>
        {paymentMethod === 'creditCard' && (
          <div>
            <label>
              Card Number
              <input type="text" value={cardNumber} placeholder='---- ---- ---- ----' onChange={(e) => setCardNumber(e.target.value)} />
            </label>
            <label>
              Name on Card
              <input type="text" value={cardName} onChange={(e) => setCardName(e.target.value)} />
            </label>
            <div className="checkout-form__section__row">
              <label>
                Expiry Date
                <input type="text" value={expiryDate} placeholder='MM-YYYY' onChange={(e) => setExpiryDate(e.target.value)} />
              </label>
              <label>
                CVV
                <input type="text" value={cvv} placeholder='***' onChange={(e) => setCvv(e.target.value)} />
              </label>
            </div>
          </div>
        )}
        {paymentMethod === 'upi' && (
  <div>
    <label>
      UPI Number
      <input type="text" placeholder='number@upi' value={upiNumber} onChange={(e) => setUpiNumber(e.target.value)} />
    </label>
  </div>
)}
{paymentMethod === 'emi' && (
  <div>
    <label>
      Card Number
      <input type="text" value={cardNumber} placeholder='---- ---- ---- ----' onChange={(e) => setCardNumber(e.target.value)} />
    </label>
    <label>
      Name on Card
      <input type="text" value={cardName} onChange={(e) => setCardName(e.target.value)} />
    </label>
    <div className="checkout-form__section__row">
      <label>
        Expiry Date
        <input type="text" value={expiryDate} placeholder='MM-YYYY' onChange={(e) => setExpiryDate(e.target.value)} />
      </label>
      <label>
        CVV
        <input type="text" value={cvv} placeholder='***' onChange={(e) => setCvv(e.target.value)} />
      </label>
      <label>
        EMI Duration
        <input type="text" value={emiDuration} placeholder='in months' onChange={(e) => setEmiDuration(e.target.value)} />
      </label>
    </div>
  </div>
)}

      </div>
      <Link to={"/orderplaced"}>
      <button type="submit" className="bg-purple-700 hover:bg-purple-50 rounded-lg text-white transition duration-300 ease-linear mt-5 border-2 border-purple-600 font-bold hover:text-purple-700 p-3 w-80">Place Your Order</button>
      </Link>
    </form>
  );
}

export default CheckoutPage;
