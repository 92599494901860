import { useState, useEffect } from "react";
import "./Items.css";

function App() {
  const [products, setProducts] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    fetch("https://fakestoreapi.com/products")
      .then((res) => res.json())
      .then((data) => {
        // Add sizes property to each product
        const productsWithSizes = data.map((product) => ({
          ...product,
          sizes: [" ","S", "M", "L"],
        }));
        setProducts(productsWithSizes);
      });
  }, []);

  return (
    <div className="total-content">
      <input
        className="search-box"
        type="text"
        value={query}
        placeholder="search for items..."
        onChange={(e) => setQuery(e.target.value)}
      />
      <ul>
        {products
          .filter((product) =>
            product.title.toLowerCase().includes(query.toLowerCase())
          )
          .map((product) => (
            <li key={product.id} className="card">
              <img src={product.image} alt={product.title} />
              <h3>{product.title}</h3>
              <p>{product.description}</p>
              <p>Rs.{product.price*80}</p>
              <label htmlFor={`size-${product.id}`}>Size:</label>
              <select id={`size-${product.id}`} name="size" className="size-box">
                {product.sizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
              <button>
                <p>ADD TO CART</p>
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default App;
