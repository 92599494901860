import React from "react";
import Product from "../components/Product";
import { products } from "../data";
import Banner from "../components/Banner";
import img1 from '../Images/carousel-2.jpg'
const Home = () => {
  return (
    <div className="w-full bg-gray-200">
      <Banner/>
      <div className="grid grid-flow-row-dense md:grid-cols-3  lg:grid-cols-4 xl:grid-cols-4 md:-mt-30">
        {products.map((item) => {
          return <Product key={item.id} item={item} />;
        })}
      </div>
      <img src={img1} className='md:col-span-full h-96 w-4/5 mx-auto cursor-pointer ' />
      <div className="grid grid-flow-row-dense md:grid-cols-3  lg:grid-cols-4 xl:grid-cols-3 md:-mt-30">
        {products.map((item) => {
          return <Product key={item.id} item={item} />;
        })}
      </div>
    </div>
  );
};

export default Home;
