import React from 'react';
import './OrderPlaced.css';
import { Link } from 'react-router-dom';

function OrderPlaced() {
  return (
    <div className="order-placed-container">
      <div className="order-placed">
        <h1>Thank You For Your Order!</h1>
        <p>Your order has been placed successfully.</p>
        <p>We will send you an email with the order details and shipping information.</p>
        <div className="order-summary">
        </div>
        <Link to={"/"}>
        <button className="continue-shopping-btn">Continue Shopping</button>
        </Link>
      </div>
    </div>
  );
}

export default OrderPlaced;
