import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import img2 from '../Images/carousel-1.jpg';
import img3 from '../Images/carousel-5.jpg';
import img4 from '../Images/carousel-3.jpg';
function Banner() {
  return (
    <div className='relative '>
        <div className='absolute w-full h-32 bg-gradient-to-t from-gray-100 to-transparent bottom-0 z-20'/>
        <Carousel 
          autoPlay
          infiniteLoop
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          interval={5000}
        >
            <div>
                <img loading='lazy' src={img2} />
            </div>
            <div>
                <img loading='lazy' src={img3} />
            </div>
            <div>
                <img loading='lazy' src={img4} />
            </div>

        </Carousel>
        

    </div>
  )
}

export default Banner